.page-footer {
    padding: 70px 0 40px; 

    a {
        color: $color-white;
        display: block;
        text-decoration: none;
    }

    background: $color-slate-gray;
    color: $color-white;
    font-size: 14px;
    line-height: 1.7;

    .footer-top,
    .footer-bottom {
        display: flex;
        flex-direction: column;

        .footer-left {   
            flex-grow: 1;
            margin-right: 0;
        }
    }

    .footer-location {

        .location-list {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .location-list-item {
        display: inline-flex;
        flex-direction: column;
        
        width: 50%;
        box-sizing: border-box;

        padding-bottom: 30px;
        padding-right: 20px;

        .location-icon {
            margin-right: 20px;
        }    

        .location-info-title {
            margin: 0;
            font-weight: bold;
        }
    }

    .navigation-list {

        .navigation-list-item {
            display: flex;
            align-items: center;

            .navigation-list-item-icon {
                margin-right: 20px;
            }
        }

        .mobile-margined {
            margin-bottom: 30px;
        }
    }

    .general-info {
        margin-top: 30px;
        padding: 20px;

        border-radius: 30px;
        border: solid 1px #ffffff;

        display: flex;
        flex-direction: column;

        .generai-info-title {
            margin-top: 10px;
        }

        .social-container {
            display: flex;
            padding-top: 20px;

            a {
                margin-right: 10px;
            }
        }
    }

    .certificates-block {
        display: flex;
        flex-direction: column;

        .certificates-block-title {
            display: block;
            margin-right: 20px;
            font-weight: bold;
        }

        .certificates-list {
            display: flex;
            padding: 10px 0 20px;

            .certificates-list-item {
                margin-right: 10px;

                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .copyright {
        font-size: 12px;
    }

    .footer-bottom {
        padding-top: 40px;
    }
}

//
//  Desktop 
//  ---------------------------------------------

@media (min-width: $screen__l) {
    .page-footer {
        font-size: 18px;
        line-height: 1.4;

        .footer-top,
        .footer-bottom {
        flex-direction: row;
        }

        .footer-location {
            padding-bottom: 60px;
            margin-right: 50px;

            .location-list {    
                justify-content: space-between;
            }

            .location-list-item {
                width: auto;    
                padding-right: 0;
                flex-direction: row;
            }
            
        }
        
        .footer-navigation {
            padding: 40px 0 50px;
            width: 50%;
            border-bottom: 1px solid $color-white ;
            border-top: 1px solid $color-white ;

            .navigation-list {
                width: 115%;
            }
        }

        .navigation-list {
            columns: 2;
            line-height: 2;

            .navigation-list-item{
                display: flex;
                align-items: center;
            }

            .mobile-margined {
                margin-bottom: 0;
            }
        }

        .general-info {
            padding: 20px 90px;
            height: 100%;
            box-sizing: border-box;
            margin: 0;

            justify-content: center;
            align-items: center;

            .generai-info-title {
                margin-top: 30px;
            }

            .social-container {
                display: flex;
                justify-content: center;
                padding-top: 50px;
            }

        }

        .certificates-block {
            flex-direction: row;
            align-items: center;
            flex-grow: 1;

            .certificates-list {
                padding: 0;

                .certificates-list-item {
                    svg {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }

        .copyright {
            font-size: 18px;
        }

    }
}





